<template>
    <div class="page_essai_provenance">
        <h2>Provenance séances d'essais</h2>
        <hr/>
        <vs-table v-model="selected" pagination max-items="20" search :data="channel" @selected="editChannel">

            <template slot="header">
                <div class="dropdown-button-container">
                    <vs-button class="btnx" type="filled" v-on:click="newChannel()" icon-pack="feather" icon="icon-plus">Nouveau</vs-button>
                </div>
            </template>

            <!-- header table -->
            <template slot="thead">
                <vs-th sort-key="produit">Nom</vs-th>
                <vs-th sort-key="categorie">color</vs-th>
                <!--TODO GROUPE-->
            </template>

            <!--Body table-->
            <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :style="{color:data[indextr].color}">
                    <vs-td>
                        <span :style="{color:data[indextr].color}">{{ data[indextr].name }}</span>
                    </vs-td>
                    <vs-td>
                        <span :style="{color:data[indextr].color}">{{ data[indextr].color }}</span>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <AddChannel ref="addChannel" />
    </div>
</template>



<style lang="scss" scoped>
.page_essai_provenance{

}
</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'
import Channel  from '@/database/models/channel'

import AddChannel from '@/components/channel/add.vue'

export default {
    components: {
		AddChannel,
	},
    data(){
        return{
            channel:[],
            selected:[],
        }
	},
	mounted:function()
	{
        //autoRefresh
		autoRefresh.SetCallback(()=>{
            //refresh
            Channel.getTabAllMemory((list_cha)=>{
                this.channel = list_cha
            })
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            
        }
    },
    methods:{
        newChannel()
        {
			this.$refs.addChannel.openPopup(undefined, ()=>{
				autoRefresh.refresh()
			})
        },
        editChannel( chan )
        {
			this.$refs.addChannel.openPopup(chan, ()=>{
				autoRefresh.refresh()
			})
        },
    }
};

</script>